import { Box, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import type { CurrentUserJson } from '../services/auth/auth.server'

interface Props {
    user: CurrentUserJson | null
    env: string
}

export default function Footer(props: Props) {
    return (
        <Box
            w="100%"
            pos="fixed"
            bottom={0}
            left={0}
            borderTop="1px solid"
            borderColor={useColorModeValue('gray.100', 'gray.700')}
            zIndex={500}
            px={4}
            py={2}
        >
            <HStack justify="space-between">
                <Text>
                    {props.user
                        ? `Logged in as: ${props.user.firstName} ${props.user.lastName} (${props.user.email})`
                        : 'Not Logged in'}{' '}
                </Text>
                {props.env !== 'production' && <Text>Environment: {props.env}</Text>}
                <Text>Version 1.2.1 &copy; Copyright Primary Health Insights 2023.</Text>
            </HStack>
        </Box>
    )
}
